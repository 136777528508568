import React, { Component } from "react";
import { Spinner, ThemeProvider } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import FloatingWhatsApp from "../components/FloatingWhatsApp";
import Footer from "../components/Footer";
import Header from "../components/Header";
import MobileMenu from "../components/MobileMenu";
import logo from "../imgs/logo.png";
import { isRTL } from "../locales/localeLang";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <ThemeProvider dir={isRTL ? "rtl" : "ltr"}>
        <div className="gloader" id="gloader">
          <img src={logo} alt="" style={{ marginBottom: 15 }} width={150} />
          <Spinner />
        </div>
        <MobileMenu />
        <div className="main-container">
          <Header />
          <div style={{ minHeight: "60vh" }}>
            <Outlet />
          </div>
          <Footer />
        </div>
        <FloatingWhatsApp />
        <ToastContainer />
      </ThemeProvider>
    );
  }
}

export default Layout;
