import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import AjaxHelper from "../helpers/AjaxHelper";
import AppUrls from "../helpers/AppUrls";
import localeRes, { isRTL } from "../locales/localeLang";
import ContactFormInputs from "./ContactFormInputs";

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  async componentDidMount() {
    var res = await AjaxHelper.get(AppUrls.GetWhyChooseUs);
    if (res) this.setState({ data: res });
  }

  render() {
    return (
      <div style={{ padding: 30 }}>
        <Container>
          <Row>
            <Col md={6} sm={12} style={{ paddingTop: 50 }}>
              <h1>{localeRes.current.why_choose}</h1>
              <ul className={"why-us" + (isRTL ? "" : " reverse")}>
                {this.state.data &&
                  this.state.data?.map((x, i) => (
                    <li key={i}>
                      <p>{isRTL ? x?.title : x?.titleEn}</p>
                    </li>
                  ))}
              </ul>
            </Col>

            <Col md={6} sm={12}>
              <ContactFormInputs />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default ContactForm;
