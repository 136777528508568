import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ContactForm from "../components/ContactForm";
import Cover from "../components/Cover";
import AjaxHelper from "../helpers/AjaxHelper";
import AppUrls from "../helpers/AppUrls";
import Loader from "../helpers/Loader";
import pay from "../imgs/pay.png";
import s1 from "../imgs/s1.png";
import s2 from "../imgs/s2.png";
import s4 from "../imgs/s4.png";
import shop from "../imgs/shop.png";
import visa from "../imgs/visa.png";
import localeRes, { isRTL } from "../locales/localeLang";

class OurServices extends Component {
  constructor(props) {
    super(props);
    this.state = { data: {} };
  }

  async componentDidMount() {
    var res = await AjaxHelper.get(AppUrls.GetOurServices);
    if (res) this.setState({ data: res });
    Loader.hide();
  }

  render() {
    return (
      <>
        <Cover img={this.state.data.bg} title={this.state.data.coverTitle} />
        <div style={{ backgroundColor: "white" }}>
          <h1
            style={{ textAlign: "center", paddingTop: 50, paddingBottom: 30 }}
          >
            {localeRes.current.our_services}
          </h1>
          <p
            style={{
              width: "80%",
              margin: "auto",
              textAlign: "center",
              fontSize: 24,
              fontWeight: "bold",
              paddingBottom: 50,
            }}
          >
            {isRTL
              ? this.state.data.desc?.title
              : this.state.data.desc?.titleEn}
          </p>
        </div>
        <div style={{ padding: "50px 0px" }}>
          <Container>
            <Row>
              {data.map((x, i) => (
                <Col key={i} sm={6} xs={12} style={{ marginBottom: 15 }}>
                  <div>
                    <img src={x.icon} width={50} alt="" />
                    <span style={{ fontSize: 30, paddingRight: 10 }}>
                      {x.title}
                    </span>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
        <div
          style={{
            backgroundColor: "white",
            paddingTop: 50,
            paddingBottom: 50,
          }}
        >
          <ContactForm />
        </div>
      </>
    );
  }
}

export default OurServices;

const data = [
  {
    icon: shop,
    title: isRTL
      ? "شحن للمتاجر الإلكترونية المحليـة"
      : "Shipping for locale online stores",
  },
  {
    icon: s4,
    title: isRTL
      ? "الشحن داخل مدن المملكة المختلفة"
      : "Shipping through many Kingdom cities",
  },
  {
    icon: s2,
    title: isRTL
      ? "توصيل الطلبات حتي باب العميل"
      : "Deliver orders to clinet's door",
  },
  {
    icon: visa,
    title: isRTL ? "الـــدفـــع عــنــد الإســـتـــلام" : "Cash on delivery",
  },
  {
    icon: s1,
    title: isRTL ? "متابعــة مسـار الشحـــنـــات" : "Track shipment status",
  },
  {
    icon: pay,
    title: isRTL ? "إدارة عملية الدفع بسهولة" : "Control payment with ease",
  },
];
