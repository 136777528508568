import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Cover from "../components/Cover";
import localeLang, { isRTL } from "../locales/localeLang";
import s1 from "../imgs/s1.png";
import s2 from "../imgs/s2.png";
import s3 from "../imgs/s3.png";
import s4 from "../imgs/s4.png";
import book from "../imgs/Mockup2.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import ContactForm from "../components/ContactForm";
import Loader from "../helpers/Loader";
import AppUrls from "../helpers/AppUrls";
import AjaxHelper from "../helpers/AjaxHelper";
import NavNames from "../helpers/NavNames";
import { Link } from "react-router-dom";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      pivots: {
        services: 0,
        choose_us: 0,
        prices: 0,
        profile: 0,
        partners: 0,
      },
    };
  }

  async componentDidMount() {
    var pivots = {
      services: this.servRef.getBoundingClientRect().top,

      choose_us: this.choosusRef.getBoundingClientRect().top,

      prices: this.pricesRef.getBoundingClientRect().top,

      profile: this.profileRef.getBoundingClientRect().top,

      partners: this.partnersRef.getBoundingClientRect().top,
    };
    this.setState({ pivots });
    var res = await AjaxHelper.get(AppUrls.GetHomeData);
    if (res) this.setState({ data: res });

    Loader.hide();
  }

  renderService(icon, title) {
    return (
      <Col md={3} sm={6} style={{ marginBottom: 20 }}>
        <div style={{ display: "flex", alignItems: "start" }}>
          <span>
            <img src={icon} alt="" width={70} />
          </span>
          <span style={{ fontWeight: "bold", marginRight: 10 }}>{title}</span>
        </div>
      </Col>
    );
  }

  renderChooseUs(title, i) {
    return (
      <Col md={4} sm={6} style={{ marginBottom: 20 }} key={i}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginLeft: 10 }}>
            {/* <img src={check} alt="" width={70} /> */}
            <FontAwesomeIcon
              icon={faCheckCircle}
              fontSize={70}
              className="icons-color"
            />
          </span>
          <span>{isRTL ? title?.title : title?.titleEn}</span>
        </div>
      </Col>
    );
  }

  renderPrices(data) {
    return (
      <Col
        md={4}
        sm={12}
        style={{ marginBottom: 20 }}
        key={data.id}
        className="mh-100"
      >
        <div
          style={{
            backgroundColor: "white",
            borderRadius: 52,
            padding: "15px 0px",
            height: "100%",
            display: "flex",
            width: "100%",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            <h3 className="text-center">{isRTL ? data.title : data.titleEn}</h3>
            <h4 className="text-center">
              {isRTL ? data.subTitle : data.subTitleEn}
            </h4>
            <div style={{ marginBottom: 20, marginTop: 25 }}>
              <ul>
                {data.pricesItems?.map((x, i) => (
                  <li
                    key={i}
                    style={{ listStyleType: "none", marginBottom: 20 }}
                  >
                    <span style={{ marginLeft: 10 }}>
                      <FontAwesomeIcon icon={faCheck} className="icons-color" />
                    </span>
                    <span>{isRTL ? x.title : x.titleEn}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="text-center">
            <Link className="price-btn" to={NavNames.contact_us}>
              {isRTL ? (
                <>
                  <FontAwesomeIcon
                    icon={faChevronCircleRight}
                    className="icons-color"
                  />
                  <span style={{ marginRight: 5 }}>
                    {isRTL ? data.buttonTitle : data.buttonTitleEn}
                  </span>
                </>
              ) : (
                <>
                  <span style={{ marginRight: 5 }}>
                    {isRTL ? data.buttonTitle : data.buttonTitleEn}
                  </span>
                  <FontAwesomeIcon
                    icon={faChevronCircleRight}
                    className="icons-color"
                  />
                </>
              )}
            </Link>
          </div>
        </div>
      </Col>
    );
  }

  render() {
    return (
      <>
        <Cover
          img={this.state.data.bg}
          title={this.state.data.coverTitle}
          // subtitle={localeLang.current.home_slogan2}
        />

        <div className="serv-container" ref={(ref) => (this.servRef = ref)}>
          <h1>{localeLang.current.our_services}</h1>
          <Container
            style={{
              backgroundColor: "#e3e3e3",
              borderRadius: 85,
              padding: "40px 30px 20px 30px",
              marginBottom: 100,
            }}
          >
            <Row>
              {this.renderService(s1, localeLang.current.serv1)}
              {this.renderService(s2, localeLang.current.serv2)}
              {this.renderService(s3, localeLang.current.serv3)}
              {this.renderService(s4, localeLang.current.serv4)}
            </Row>
          </Container>
        </div>
        <div ref={(ref) => (this.choosusRef = ref)}>
          <Container fluid className="ch-us-container">
            <Row className="ch-us-container-overlay">
              <Col>
                <h1
                  style={{
                    fontSize: 40,
                    marginBottom: 30,
                    textAlign: "center",
                    marginTop: 20,
                  }}
                >
                  {isRTL
                    ? this.state.data.whyUsHeader?.title
                    : this.state.data.whyUsHeader?.titleEn}
                </h1>
                <Row>
                  <Col>
                    <Container>
                      <Row>
                        {this.state.data.whyUs?.map((x, i) =>
                          this.renderChooseUs(x, i)
                        )}
                        {/* {this.renderChooseUs("الربط التقني API")}
                        {this.renderChooseUs("طاقم عمل يستخدم احدث التقنيات")}
                        {this.renderChooseUs("قنوات أتصال متعددة")}
                        {this.renderChooseUs("اسعارنا تناسب تجارتك")}
                        {this.renderChooseUs("نأخذ أراء عملائنا بعين الأعتبار")}
                        {this.renderChooseUs("نسابق الزمن لتوصيل الشحنات")} */}
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>

        <h4
          style={{
            margin: "auto",
            marginTop: 50,
            marginBottom: 50,
            width: "80%",
            borderRadius: 30,
            backgroundColor: "#ffffff",
            padding: "20px 5px",
            textAlign: "center",
          }}
        >
          {isRTL
            ? this.state.data.pricesHeader?.title
            : this.state.data.pricesHeader?.titleEn}
        </h4>

        <div style={{ marginBottom: 50 }} ref={(ref) => (this.pricesRef = ref)}>
          <Container>
            <Row>
              {this.state.data.prices?.map((x) => this.renderPrices(x))}
              {/* {this.renderPrices(data1)}
              {this.renderPrices(data2)}
              {this.renderPrices(data3)} */}
            </Row>
          </Container>
        </div>

        <div
          style={{ backgroundColor: "white", padding: "50px 0px" }}
          ref={(ref) => (this.profileRef = ref)}
        >
          <div>
            <Container>
              <Row>
                <Col
                  md={6}
                  sm={4}
                  xs={12}
                  style={{ zIndex: 2, textAlign: "center" }}
                >
                  <img src={book} className="book-img" alt="" />
                </Col>
                <Col md={6} sm={8} xs={12} className="book-container">
                  <p style={{ marginBottom: 50 }}>
                    {isRTL
                      ? this.state.data.profile?.title
                      : this.state.data.profile?.titleEn}
                  </p>
                  <p style={{ lineHeight: 2 }}>
                    {localeLang.current.download_company_profile}
                    <a
                      className="profile-download"
                      href={this.state.data.profileFile}
                    >
                      {" "}
                      {localeLang.current.download_profile}
                    </a>
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
          <div
            style={{ margin: "50px 0px" }}
            ref={(ref) => (this.partnersRef = ref)}
          >
            {this.state.data.partners?.length > 0 && (
              <h1 className="text-center">{localeLang.current.our_partners}</h1>
            )}
            <Container>
              <Row>
                <Col>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 30,
                        flexWrap: "wrap",
                      }}
                    >
                      {this.state.data.partners?.map((x, i) => (
                        <div key={i} style={{ width: "130px", margin: 15 }}>
                          <a
                            key={i}
                            href="#5"
                            style={{
                              display: "inline-block",
                              margin: "0px 20px",
                              width: "100%",
                            }}
                          >
                            <img src={x} alt="" style={{ width: "100%" }} />
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div style={{ marginBottom: 50, marginTop: 30 }}>
          <ContactForm />
        </div>
      </>
    );
  }
}

export default Home;
