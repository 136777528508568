import {
  faFacebook,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import NavNames from "../helpers/NavNames";
import localeRes, { isRTL, setLang } from "../locales/localeLang";
import $ from "jquery";

class MobileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderItem(title, url) {
    return (
      <li style={{ listStyleType: "none" }}>
        <Link
          onClick={() => {
            $(".m-menu-c").removeClass("active");
          }}
          to={url}
        >
          {title}
        </Link>
      </li>
    );
  }

  socialItem(icon, link) {
    return (
      <a href={link} target="_blank" rel="noreferrer" className="m-social-lnk">
        <FontAwesomeIcon icon={icon} color="white" fontSize={26} />
      </a>
    );
  }

  render() {
    return (
      <div className="m-menu-c">
        <div
          style={{
            position: "relative",
            display: "inline-block",
            right: 24,
            top: 15,
          }}
        >
          <Button
            variant="link"
            onClick={() => {
              $(".m-menu-c").removeClass("active");
            }}
          >
            <FontAwesomeIcon
              icon={faTimes}
              className="menu-bars"
              fontSize={26}
            />
          </Button>
        </div>
        <div style={{ margin: 20, textAlign: "center" }}>
          <img
            src={require("../imgs/logo.png")}
            alt={localeRes.current.app_name}
            style={{ height: 70 }}
          />
        </div>
        <ul style={{ margin: 0, padding: 0, marginTop: 30 }}>
          {this.renderItem(localeRes.current.home, NavNames.home)}
          {this.renderItem(localeRes.current.about, NavNames.about)}
          {this.renderItem(
            localeRes.current.our_services,
            NavNames.our_services
          )}
          {this.renderItem(localeRes.current.contact_us, NavNames.contact_us)}
          {/* {this.renderItem(localeRes.current.jobs, NavNames.jobs)} */}
          <li style={{ listStyleType: "none" }}>
            <a
              href="#5"
              className="header-lnk"
              onClick={() => {
                setLang(isRTL);
              }}
            >
              {localeRes.current.lang}
            </a>
          </li>
        </ul>
        {/* <div style={{ textAlign: "center", marginTop: 20 }}>
          {this.socialItem(faFacebook, localeRes.current.default_url)}
          {this.socialItem(faInstagram, localeRes.current.default_url)}
          {this.socialItem(faTwitter, localeRes.current.default_url)}
        </div> */}
      </div>
    );
  }
}

export default MobileMenu;
