import {
  faApple,
  faFacebook,
  faGoogle,
  faGooglePlay,
  faInstagram,
  faLinkedin,
  faTwitter,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faAt,
  faExclamation,
  faMobileScreen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";

/**
 * @extends {React.Component<Props, {}>}
 * @typedef Props
 * @prop {object} data
 */
class SocialMediaHelper extends Component {
  constructor(props) {
    super(props);
    this.state = { icon: faExclamation, url_type: 0 };
  }

  //   Facebook = 0,
  //         Twitter = 1,
  //         Instagram = 2,
  //         Linkedin = 3,
  //         youtube = 4,
  //         Google = 5,
  //         WhatsApp = 6,
  //         GooglePlay = 7,
  //         AppStore = 8,

  componentDidMount() {
    switch (this.props.data.socialMediaType) {
      case 0:
        this.setState({ icon: faFacebook });
        break;
      case 1:
        this.setState({ icon: faTwitter });
        break;
      case 2:
        this.setState({ icon: faInstagram });
        break;
      case 3:
        this.setState({ icon: faLinkedin });
        break;
      case 4:
        this.setState({ icon: faYoutube });
        break;
      case 5:
        this.setState({ icon: faGoogle });
        break;
      case 6:
        this.setState({ icon: faWhatsapp });
        break;
      case 7:
        this.setState({ icon: faGooglePlay });
        break;
      case 8:
        this.setState({ icon: faApple });
        break;
      case 9:
        this.setState({ icon: faMobileScreen, url_type: 1 });
        break;
      case 10:
        this.setState({ icon: faAt, url_type: 2 });
        break;
      default:
        break;
    }
  }

  getUrl() {
    switch (this.state.url_type) {
      case 1:
        return `tel:${this.props.data.url}`;
      case 2:
        return `mailto:${this.props.data.url}`;
      default:
        return this.props.data.url;
    }
  }

  getTarget() {
    switch (this.state.url_type) {
      case 1:
        return `_self`;
      case 2:
        return "_self";
      default:
        return `_blank`;
    }
  }

  render() {
    return (
      <a href={this.getUrl()} target={this.getTarget()} rel="noreferrer">
        <FontAwesomeIcon icon={this.state.icon} color="white" fontSize={26} />
      </a>
    );
  }
}

export default SocialMediaHelper;
