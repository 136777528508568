import React, { Component } from "react";
import $ from "jquery";
import AppUrls from "../helpers/AppUrls";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import localeRes from "../locales/localeLang";

/**
 * @extends {React.Component<Props, {}>}
 * @typedef Props
 * @prop {Element} title
 * @prop {string} bg
 * @prop {string} inputBg
 */
class ContactFormInputs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDetails: this.initOrderDetails,
      isloading: false,
      error: "",
    };
  }

  initOrderDetails = { name: "", phone: "", email: "" };

  doSubmit() {
    if (this.state.orderDetails.name.trim() === "") {
      this.setState({ error: localeRes.current.name_required });
      return;
    }
    if (
      this.state.orderDetails.phone.trim() === "" &&
      this.state.orderDetails.phone.trim() === ""
    ) {
      this.setState({ error: localeRes.current.phone_email_required });
      return;
    }
    this.setState({ isloading: true, error: "" });
    var that = this;
    $.ajax({
      type: "POST",
      url: AppUrls.SendRequest,
      data: JSON.stringify(this.state.orderDetails),
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      success: function (data) {
        that.setState({
          isloading: false,
          error: "",
          orderDetails: that.initOrderDetails,
        });
        toast("شكراً لاختيارك لنا");
      },
      error: function (errMsg) {
        that.setState({ isloading: false, error: "فشل الارسال" });
      },
    });
  }

  inputStyle = this.props.inputBg
    ? {
        backgroundColor: this.props.inputBg,
      }
    : {};

  render() {
    return (
      <div
        style={{
          backgroundColor: this.props.bg || "#ebebeb",
          borderRadius: 58,
          padding: 30,
        }}
      >
        {this.props.title || (
          <h1 className="text-center">{localeRes.current.request_offer}</h1>
        )}
        <div>
          <div className="order-form">
            <label>{localeRes.current.name}</label>
            <input
              type={"text"}
              style={this.inputStyle}
              value={this.state.orderDetails.name}
              onChange={(e) => {
                this.setState({
                  orderDetails: {
                    ...this.state.orderDetails,
                    name: e.target.value,
                  },
                });
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  this.phoneRef.focus();
                }
              }}
            />
          </div>
          <div className="order-form">
            <label>{localeRes.current.phone}</label>
            <input
              ref={(ref) => (this.phoneRef = ref)}
              type={"tel"}
              style={this.inputStyle}
              value={this.state.orderDetails.phone}
              onChange={(e) => {
                this.setState({
                  orderDetails: {
                    ...this.state.orderDetails,
                    phone: e.target.value,
                  },
                });
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  this.emailRef.focus();
                }
              }}
            />
          </div>
          <div className="order-form">
            <label>{localeRes.current.email}</label>
            <input
              type={"email"}
              style={this.inputStyle}
              value={this.state.orderDetails.email}
              onChange={(e) => {
                this.setState({
                  orderDetails: {
                    ...this.state.orderDetails,
                    email: e.target.value,
                  },
                });
              }}
              ref={(ref) => (this.emailRef = ref)}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  this.doSubmit();
                }
              }}
            />
          </div>
          <div className="text-center">
            <button
              href="#5"
              className="order-btn"
              onClick={() => {
                if (!this.state.isloading) {
                  this.doSubmit();
                }
              }}
            >
              {localeRes.current.send}
            </button>
            {this.state.isloading && <Spinner size="sm" />}
          </div>
          <div className="text-center">
            {this.state.error && (
              <span style={{ color: "red" }}>{this.state.error}</span>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ContactFormInputs;
