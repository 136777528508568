import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import AjaxHelper from "../helpers/AjaxHelper";
import AppUrls from "../helpers/AppUrls";

class FloatingWhatsApp extends Component {
  constructor(props) {
    super(props);
    this.state = { whats_num: "", color: "greenyellow" };
  }

  async componentDidMount() {
    var res = await AjaxHelper.get(AppUrls.GetFixedWhatsApp);
    if (res) this.setState({ whats_num: res.num, color: res.color });
  }

  render() {
    return (
      this.state.whats_num && (
        <a
          target={"_blank"}
          href="https://wa.me/966530302278"
          style={{
            position: "fixed",
            right: 50,
            bottom: 50,
            zIndex: 999,
            color: this.state.color ? this.state.color : "greenyellow",
            fontSize: 40,
          }}
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faWhatsapp} />
        </a>
      )
    );
  }
}

export default FloatingWhatsApp;
