import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import AjaxHelper from "../helpers/AjaxHelper";
import AppUrls from "../helpers/AppUrls";
import Loader from "../helpers/Loader";
import localeRes, { isRTL } from "../locales/localeLang";

class Privacy extends Component {
  constructor(props) {
    super(props);
    this.state = { data: "" };
  }

  async componentDidMount() {
    var res = await AjaxHelper.get(AppUrls.GetPrivacy);
    if (res) this.setState({ data: res });
    Loader.hide();
  }

  render() {
    return (
      <Container>
        <Row>
          <Col>
            <div>
              <h1 style={{ margin: "50px 0px" }}>
                {localeRes.current.privacy}
              </h1>
              <p
                style={{
                  marginBottom: 50,
                  textAlign: "justify",
                  whiteSpace: "pre-wrap",
                }}
              >
                {isRTL ? this.state.data?.title : this.state.data?.titleEn}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Privacy;
