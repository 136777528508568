import $ from "jquery";

const Loader = {
  show: () => {
    $("#gloader").fadeIn(100);
  },
  hide: () => {
    setTimeout(() => {
      $("#gloader").fadeOut(500);
    }, 500);
  },
};

export default Loader;
