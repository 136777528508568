const ar = {
  lang: "English",
  app_name: "ALSANEA Logistics",
  default_url: "https://google.com",
  home: "الرئيسيه",
  about: "من نحن",
  our_services: "خدماتنا",
  contact_us: "تواصل معنا",
  jobs: "الوظائف",
  home_slogan1: "أجعل أعمالك التجاريه",
  home_slogan2: "تنمو بشكل أسرع",
  serv1: "التوصيل السريع خلال ساعتين من تحويل الطلب الي جاهز",
  serv2: "التوصيل العادي خلال 12 ساعه من تحويل الطلب الي جاهز",
  serv3:
    "التوصيل المجدول خلال 24 ساعه من استلام المخزون في أوقات محددة  بشكل يومي",
  serv4:
    "مدن المملكة نوفر شحن داخل جميع مدن المملكه من 3 أيام الي اربع أيام عمل",
  download_company_profile: "قم بتحميل بروفايل الشركه",
  download_profile: "تحميل البروفايل",
  our_partners: "شركاء النجاح",
  fast_links: "روابط سريعه",
  privacy: "سياسه الخصوصيه",
  why_choose: "لماذا تختار الصانع",
  name: "الأسم",
  phone: "رقم الجوال",
  email: "الإيميل",
  request_offer: "أطلب عرض سعر الأن",
  send: "ارسال",
  about_company: "عن الشركه",
  our_vision: "رؤيتنا",
  our_message: "رسالتنا",
  our_goal: "هدفنا",
  name_required: "الاسم مطلوب",
  phone_email_required: "الايميل او رقم الجوال مطلوب",
  shipping: "الشحن",
  storage: "التخزين",
  packaging: "التغليف",
};

export default ar;
