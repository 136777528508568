import Cookies from "universal-cookie";
import ar from "./ar";
import en from "./en";

export const isRTL = getLang() !== "en";

const localeRes = {
  current: isRTL ? ar : en,
};

export default localeRes;

export function setLang(isEn) {
  const cookies = new Cookies();
  cookies.set("lang", isEn ? "en" : "ar");
  window.location.reload();
}

export function getLang() {
  const cookies = new Cookies();
  return cookies.get("lang");
}
