const NavNames = {
  home: "/",
  about: "/about",
  our_services: "/our_services",
  contact_us: "/contact_us",
  jobs: "/jobs",
  privacy: "/privacy",
};

export default NavNames;
