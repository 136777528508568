import React, { Component } from "react";
import { isRTL } from "../locales/localeLang";

/**
 * @extends {React.Component<Props, {}>}
 * @typedef Props
 * @prop {string} img
 * @prop {string} title
 * @prop {string} subtitle
 */
class Cover extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div
        className="cover-img"
        style={{
          backgroundImage: `url(${this.props.img})`,
          backgroundPosition: "center",
        }}
      >
        <div className="cover-img-overlay">
          <h2>
            {isRTL ? this.props.title?.title : this.props.title?.titleEn}
            {this.props.subtitle && <br />}
            {this.props.subtitle}
          </h2>
        </div>
      </div>
    );
  }
}

export default Cover;
