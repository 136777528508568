import {
  faBoxOpen,
  /*faComment,*/ faEye,
  faRocket,
  faTruckFast,
  faWarehouse,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Cover from "../components/Cover";
import AjaxHelper from "../helpers/AjaxHelper";
import AppUrls from "../helpers/AppUrls";
import Loader from "../helpers/Loader";
import localeRes, { isRTL } from "../locales/localeLang";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = { data: {} };
  }

  async componentDidMount() {
    var res = await AjaxHelper.get(AppUrls.GetAboutData);
    if (res) this.setState({ data: res });

    Loader.hide();
  }

  renderItem(icon, title, desc, key) {
    return (
      <Col key={key} md={4} sm={12} style={{ marginBottom: 20 }}>
        <div
          style={{
            backgroundColor: "white",
            padding: 20,
            borderRadius: 30,
            height: "100%",
          }}
        >
          <div className="text-center">
            <FontAwesomeIcon
              icon={icon}
              className="icons-color"
              fontSize={36}
            />
            <h5 style={{ margin: "15px 0px" }}>{title}</h5>
          </div>
          <p style={{ textAlign: "justify", whiteSpace: "pre-wrap" }}>
            {isRTL ? desc?.title : desc?.titleEn}
          </p>
        </div>
      </Col>
    );
  }

  render() {
    return (
      <div>
        <Cover img={this.state.data.bg} title={this.state.data.coverTitle} />
        <div
          style={{
            backgroundColor: "white",
            paddingTop: 70,
            paddingBottom: 50,
          }}
        >
          <Container>
            <Row>
              <Col>
                <h3>{localeRes.current.about_company}</h3>
                <p style={{ whiteSpace: "pre-wrap", textAlign: "justify" }}>
                  {isRTL
                    ? this.state.data.about?.title
                    : this.state.data.about?.titleEn}
                </p>
              </Col>
              <Col md={4} xs={12}>
                <img
                  src={this.state.data.img}
                  alt=""
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Container>
        </div>

        <div style={{ padding: "50px 0px", marginBottom: 30 }}>
          <Container>
            <Row>
              {/* {data.map((x, i) => this.renderItem(x.icon, x.title, x.desc, i))} */}
              {this.renderItem(
                faEye,
                localeRes.current.our_vision,
                this.state.data.vison
              )}
              {/* {this.renderItem(
                faComment,
                localeRes.current.our_message,
                this.state.data.message
              )} */}
              {this.renderItem(
                faRocket,
                localeRes.current.our_goal,
                this.state.data.goal
              )}

              {this.renderItem(
                faWrench,
                localeRes.current.our_services,
                this.state.data.services
              )}
            </Row>
            <br />
            <br />
            <Row>
              {this.renderItem(
                faTruckFast,
                localeRes.current.shipping,
                this.state.data.shipping
              )}
              {this.renderItem(
                faWarehouse,
                localeRes.current.storage,
                this.state.data.storage
              )}
              {this.renderItem(
                faBoxOpen,
                localeRes.current.packaging,
                this.state.data.packaging
              )}
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default About;
