import { HashRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import About from "./fiels/About";
import ContactUs from "./fiels/ContactUs";
import Home from "./fiels/Home";
import Jobs from "./fiels/Jobs";
import Layout from "./fiels/Layout";
import OurServices from "./fiels/OurServices";
import Privacy from "./fiels/Privacy";
import NavNames from "./helpers/NavNames";
import ScrollToTop from "./helpers/ScrollToTop";

function App() {
  return (
    <HashRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path={NavNames.about} element={<About />} />
          <Route path={NavNames.contact_us} element={<ContactUs />} />
          <Route path={NavNames.jobs} element={<Jobs />} />
          <Route path={NavNames.our_services} element={<OurServices />} />
          <Route path={NavNames.privacy} element={<Privacy />} />
        </Route>
      </Routes>
    </HashRouter>
  );
}

export default App;
