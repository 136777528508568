const en = {
  lang: "العربية",
  app_name: "M3AAK DIYMN",
  default_url: "https://google.com",
  home: "Home",
  about: "About",
  our_services: "Our Services",
  contact_us: "Contact us",
  jobs: "Jobs",
  serv1: "Fast delivery within 2 hours after request is ready",
  serv2: "Normal delivery within 12 hours after request is ready",
  serv3:
    "Scheduled delivery within 24 hours after stock is recieved on fixed times daily",
  serv4: "Dileviry for Kingdom cities is available with 3-4 working days",
  download_company_profile: "Download our company profile",
  download_profile: "Download profile",
  our_partners: "Our partners",
  fast_links: "Links",
  privacy: "Privacy policy",
  why_choose: "Why choose ALSANEA Logistics",
  name: "Name",
  phone: "Phone",
  email: "Email",
  request_offer: "Request a price offer now",
  send: "Send",
  about_company: "About our company",
  our_vision: "Our Vision",
  our_message: "Our Message",
  our_goal: "Our Goal",
  name_required: "Name is required",
  phone_email_required: "E-Mail or Phone are required",
  shipping: "Shipping",
  storage: "Storage",
  packaging: "Packaging",
};

export default en;
