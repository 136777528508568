import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import NavNames from "../helpers/NavNames";
import localeRes, { isRTL, setLang } from "../locales/localeLang";
import $ from "jquery";
import SocialMediaHelper from "../helpers/SocialMediaHelper";
import AjaxHelper from "../helpers/AjaxHelper";
import AppUrls from "../helpers/AppUrls";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { socials: [] };
  }

  async componentDidMount() {
    var res = await AjaxHelper.get(AppUrls.GetSocialMedia);
    if (res) this.setState({ socials: res });
  }

  socialItem(data) {
    return (
      <li key={data.id}>
        <SocialMediaHelper data={data} />
      </li>
    );
  }

  menuItem(title, route) {
    return (
      <li>
        <Link className="header-lnk" to={route}>
          {title}
        </Link>
      </li>
    );
  }

  render() {
    return (
      <Container fluid>
        <Row className="header-row">
          <Col>
            <div className="main-header">
              <div className="mn-socials">
                <ul className="d-none d-sm-block">
                  {/* {this.state.socials?.map((x) => this.socialItem(x))} */}
                  {/* {this.socialItem(faFacebook, localeLang.current.default_url)}
                  {this.socialItem(faInstagram, localeLang.current.default_url)}
                  {this.socialItem(faTwitter, localeLang.current.default_url)} */}
                </ul>
                <div className="d-block d-sm-none">
                  <Button
                    variant="link"
                    onClick={() => {
                      $(".m-menu-c").addClass("active");
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faBars}
                      className="menu-bars"
                      fontSize={26}
                    />
                  </Button>
                </div>
              </div>

              <div className="mn-links">
                <div className="d-none d-sm-block">
                  <ul>
                    {this.menuItem(localeRes.current.home, NavNames.home)}
                    {this.menuItem(localeRes.current.about, NavNames.about)}
                    {this.menuItem(
                      localeRes.current.our_services,
                      NavNames.our_services
                    )}
                    {this.menuItem(
                      localeRes.current.contact_us,
                      NavNames.contact_us
                    )}
                    {/* {this.menuItem(localeRes.current.jobs, NavNames.jobs)} */}
                    <li>
                      <a
                        href="#5"
                        className="header-lnk"
                        onClick={() => {
                          setLang(isRTL);
                        }}
                      >
                        {localeRes.current.lang}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="logo">
                <Link to={NavNames.home}>
                  <img
                    src={require("../imgs/logo.png")}
                    alt={localeRes.current.app_name}
                  />
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Header;
