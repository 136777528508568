import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import AjaxHelper from "../helpers/AjaxHelper";
import AppUrls from "../helpers/AppUrls";
import NavNames from "../helpers/NavNames";
import SocialMediaHelper from "../helpers/SocialMediaHelper";
import logo from "../imgs/logo.png";
import localeRes, { isRTL } from "../locales/localeLang";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = { socials: [], contacts: [] };
  }

  async componentDidMount() {
    var res = await AjaxHelper.get(AppUrls.GetSocialMedia);
    if (res) this.setState({ socials: res });

    var con = await AjaxHelper.get(AppUrls.GetContacts);
    if (con) this.setState({ contacts: con });
  }

  socialItem(data) {
    return (
      <li key={data.id}>
        <SocialMediaHelper data={data} />
      </li>
    );
  }

  renderContactItem(x) {
    var desc = isRTL ? x.desc : x.descEn;
    switch (x.contactItemType) {
      case 1:
        return (
          <a href={`tel:${desc}`} style={{ color: "white" }}>
            {desc}
          </a>
        );
      case 2:
        return (
          <a href={`mailto:${desc}`} style={{ color: "white" }}>
            {desc}
          </a>
        );
      case 3:
        return (
          <a href={desc} target="_blank" style={{ color: "white" }}>
            {desc}
          </a>
        );
      default:
        return desc;
    }
  }

  render() {
    return (
      <>
        <div className="main-footer">
          <Container>
            <Row>
              <Col md={4} sm={12} className="text-center">
                <div style={{ marginTop: 30 }}>
                  <img src={logo} alt="" style={{ width: "60%" }} />
                </div>
                <div className="mn-socials" style={{ marginTop: 30 }}>
                  <ul>
                    {this.state.socials?.map((x) => this.socialItem(x))}
                    {/* {this.socialItem(
                      faFacebook,
                      localeLang.current.default_url
                    )}
                    {this.socialItem(
                      faInstagram,
                      localeLang.current.default_url
                    )}
                    {this.socialItem(faTwitter, localeLang.current.default_url)} */}
                  </ul>
                </div>
              </Col>
              <Col
                lg={2}
                md={3}
                sm={6}
                xs={12}
                className="offset-md-1 offset-lg-1"
              >
                <div
                  className={"footer-links" + (isRTL ? "" : " reverse")}
                  style={{ borderLeft: "" }}
                >
                  <h5>{localeRes.current.fast_links}</h5>
                  <ul>
                    <li>
                      <Link to={NavNames.home}>{localeRes.current.home}</Link>
                    </li>
                    <li>
                      <Link to={NavNames.contact_us}>
                        {localeRes.current.contact_us}
                      </Link>
                    </li>
                    <li>
                      <Link to={NavNames.about}>{localeRes.current.about}</Link>
                    </li>
                    <li>
                      <Link to={NavNames.our_services}>
                        {localeRes.current.our_services}
                      </Link>
                    </li>
                    <li>
                      <Link to={NavNames.privacy}>
                        {localeRes.current.privacy}
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={4} sm={6} xs={12}>
                <div>
                  <h5 style={{ marginBottom: 30 }}>
                    {localeRes.current.contact_us}
                  </h5>
                  {this.state.contacts?.map((x) => (
                    <p key={x.id}>
                      {isRTL ? x.title : x.titleEn}: {this.renderContactItem(x)}
                    </p>
                  ))}
                  {/* <p>
                    العنوان : المملكه العربيه السعوديه <br /> الرياض - العليا
                  </p>
                  <p>التليفون : 8001240416</p>
                  <p>الأيميل : info@m3aak.sa</p> */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="copyright text-center">
          Built by Skyline Digital Solutions
        </div>
      </>
    );
  }
}

export default Footer;
