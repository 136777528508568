import React, { Component } from "react";
import Loader from "../helpers/Loader";

class Jobs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    Loader.hide();
  }

  render() {
    return <></>;
  }
}

export default Jobs;
