import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ContactFormInputs from "../components/ContactFormInputs";
import Cover from "../components/Cover";
import AjaxHelper from "../helpers/AjaxHelper";
import AppUrls from "../helpers/AppUrls";
import Loader from "../helpers/Loader";
import localeRes, { isRTL } from "../locales/localeLang";

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = { data: {} };
  }

  async componentDidMount() {
    var res = await AjaxHelper.get(AppUrls.GetContactUsData);
    if (res) this.setState({ data: res });
    Loader.hide();
  }

  headerColor = "#287198";
  render() {
    return (
      <>
        <Cover img={this.state.data.bg} title={this.state.data.coverTitle} />

        <div style={{ padding: "70px 0px", backgroundColor: "white" }}>
          <Container>
            <Row>
              <Col md={6} style={{ marginBottom: 30 }}>
                <h3
                  style={{
                    color: this.headerColor,
                    marginBottom: 25,
                    marginTop: 40,
                  }}
                >
                  {localeRes.current.contact_us}
                </h3>
                <div>
                  {this.state.data.contacts?.map((x, i) => (
                    <p key={i} style={{ fontSize: 24 }}>
                      <span style={{ color: "#287198" }}>
                        {isRTL ? x.title : x.titleEn}:
                      </span>{" "}
                      {isRTL ? x.desc : x.descEn}
                    </p>
                  ))}
                </div>
                <div style={{ marginTop: 25 }}>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3624.524981518833!2d46.659614575366014!3d24.70885087802853!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjTCsDQyJzMxLjkiTiA0NsKwMzknNDMuOSJF!5e0!3m2!1sen!2seg!4v1701064636465!5m2!1sen!2seg"
                    loading="lazy"
                    title="location"
                    style={{ width: "100%", height: 300 }}
                  ></iframe>
                </div>
              </Col>
              <Col md={6}>
                <div>
                  <ContactFormInputs
                    title={
                      <h3
                        style={{
                          color: this.headerColor,
                          marginBottom: 25,
                          textAlign: "center",
                        }}
                      >
                        {localeRes.current.request_offer}
                      </h3>
                    }
                    bg="transparent"
                    inputBg="#ececec"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default ContactUs;
