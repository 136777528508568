// const domain = "https://localhost:44352/api/baseapi/";
const domain = "https://beshooapp.com.temporary-domain.com/api/baseapi/";

const AppUrls = {
  home_cover: domain + "GetCover?id=0",
  GetWhyUs: domain + "GetWhyUs",
  GetHomeData: domain + "GetHomeData",
  GetWhyChooseUs: domain + "GetWhyChooseUs",
  GetSocialMedia: domain + "GetSocialMedia",
  SendRequest: domain + "SendRequest",
  GetAboutData: domain + "GetAboutData",
  GetContacts: domain + "GetContacts",
  GetContactUsData: domain + "GetContactUsData",
  GetOurServices: domain + "GetOurServices",
  GetPrivacy: domain + "GetPrivacy",
  GetFixedWhatsApp: domain + "GetFixedWhatsApp",
};

export default AppUrls;
